// inline-css-1.html.twigをコピーしてきた
// TODO: 出自がよくわからないのでそのうち消す
#firstComeWrapLogined {
  background: #fff none repeat scroll 0 0;
  margin: 0 0 14px;
  padding: 0 0 5px;
}

#firstComeWrapLogined .sideRecBox {
  margin-left: 30px;
}

#firstComeWrapLogined .sideRecBox img {
  margin-right: 8px;
}

#firstComeWrapLogined .sideRecBox ul {
  margin-left: -40px;
}

#firstComeWrapLogined .sideRecBox ul li a {
  margin-left: 40px;
  margin-bottom: 5px;
}

#firstComeWrapLogined .sideRecBox div.member-rank {
  margin: 0 0 0 -15px;
  font-weight: bold;
}

#firstComeWrapLogined .sideRecBox div.member-rank-explain {
  margin: 0;
  text-align: right;
  font-size: 93%;
}

#firstComeWrapLogined .sideRecBox div.member-rank span {
  color: #e40007;
  font-size: 110%;
}

#firstComeWrapLogined .sideRecBox div.coin-status {
  margin: 0 0 0px -15px;
  font-weight: bold;
}

#firstComeWrapLogined .sideRecBox div.coin-status span {
  color: #e40007;
  font-size: 110%;
}

#firstComeWrapLogined .sideRecBox div.coin-limit-status {
  margin: 0 0 10px -5px;
  font-weight: bold;
}

#firstComeWrapLogined .sideRecBox div.coin-limit-status span {
  color: #e40007;
  font-size: 110%;
}

#firstComeWrapLogined .sideRecBox ul li:nth-child(1) {
  background: url(/louis/pc/img/common/aside_menu_icon01.png) no-repeat 15px 50%;
}

#firstComeWrapLogined .sideRecBox ul li:nth-child(2) {
  background: url(/louis/pc/img/common/aside_menu_icon07.png) no-repeat 15px 50%;
}

#firstComeWrapLogined .sideRecBox ul li:nth-child(3) {
  background: url(/louis/pc/img/common/aside_menu_icon08.png) no-repeat 15px 50%;
}

#firstComeWrapLogined .sideRecBox ul li:nth-child(4) {
  background: url(/louis/pc/img/common/aside_menu_icon_sequels.png) no-repeat 15px 50%;
}

#firstComeWrapLogined .sideRecBox ul li:nth-child(5) {
  background: url(/louis/sp/img/common/bg-menu14.png) no-repeat 17px 50%;
  background-size: auto 100%;
}

#firstComeWrapLogined .sideRecBox ul li:nth-child(7) {
  background: url(/louis/pc/img/common/aside_menu_icon02.png) no-repeat 15px 50%;
}

#firstComeWrapLogined .sideRecBox ul li:nth-child(8) {
  background: url(/louis/pc/img/common/aside_menu_icon03.png) no-repeat 15px 50%;
}

#firstComeWrapLogined .sideRecBox ul li:nth-child(9) {
  background: url(/louis/pc/img/common/aside_menu_icon04.png) no-repeat 15px 50%;
}

#firstComeWrapLogined .sideRecBox ul li:nth-child(10) {
  background: url(/louis/pc/img/common/aside_menu_icon05.png) no-repeat 15px 50%;
}

#firstComeWrapLogined .sideRecBox ul li:nth-child(11) {
  background: url(/louis/pc/img/common/aside_menu_icon06.png) no-repeat 15px 50%;
}

.txt_new {
  color: #e40007;
  display: inline-block;
  margin-left: 5px;
  font-size: 77%;
  font-weight: bold;
}

.asideWrap {
  background: none repeat scroll 0% 0% #FFF;
  margin: 0px 0px 14px;
  padding: 0px 0px 5px;
}

.sideRecBox ul li {
  margin-left: 10px;
  margin-bottom: 5px;
}

.sideFollowUl {
  width: 226px;
  height: 35px;
  margin: 6px 0px;
}

.sideFollowUl li {
  float: left;
  width: 37px;
}

.sideFollowUl li:nth-child(1) {
  margin-left: 9px;
}

.sideFollowUl li:nth-child(2) {
  margin-left: 5px;
  margin-right: 0px;
}

.sideFollowUl li:nth-child(3) {
  margin-left: 5px;
  margin-right: 0px;
}

.sideFollowUl li:nth-child(4) {
  margin-left: 5px;
  margin-right: 0px;
}

.sideFollowUl li:nth-child(5) {
  margin-left: 5px;
  margin-right: 9px;
}

/*===
SNS一覧
=======*/
.snsAlllist {
  text-align: right;
  padding: 5px 25px 0 0;
}

.snsAlllist a:after {
  display: block;
  content: "";
  position: absolute;
  right: 15px;
  width: 0;
  height: 0;
  margin: -13px 0 0 0;
  border-top: 8px solid #1b4558;
  border-left: 8px solid transparent;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.staffrecWrap {
  width: 49.0%;
  margin-bottom: 5px;
  min-height: 300px;
}

#staffrec-box-wrapper div.staffrecWrap,
#staffrec-box-wrapper div.staffrecWrap.right {
  float: left;
}

.newsSlider li {
  width: 728px;
}

.member-rank-explain {
  margin-bottom: 10px !important;
}

.sideRecBox .bonusrank {
  font-weight: bold;
  background: #f9eaeb;
  margin: 4px 0 3px -15px;
  padding: 4px 3px 3px 3px;
  text-align: center;
  line-height: 17px;
  letter-spacing: 0.4px;
}

.sideRecBox .norank {
  font-weight: bold;
  background: #f9eaeb;
  margin: 4px 0 3px -15px;
  padding: 4px 3px 3px 3px;
  text-align: center;
  line-height: 17px;
  letter-spacing: 0.4px;
  font-size: 90%;
}

.font_rankred {
  color: #e40007;
  font-size: 110%;
}
