@CHARSET "UTF-8";
#announcement {
	height: 28px;
	line-height: 30px;
	max-width: 1040px;
	min-width: 240px;
	margin: 4px auto 10px;
	overflow: hidden;

	+ .bnr-guerrilla, .a-top-bnr__beginner {
		margin-top: 2px;
	}
}
#announcement .title {
	float: left;
	height: 28px;
	padding: 0 3px;
	background-color:#ffffff;
	width: 12%;
	text-align: center;
	font-size: 13px;
	font-weight:bold;
	margin-right: 0.6%;
}
#announcement .text {
	float: left;
	height: 28px;
	overflow: hidden;
	padding: 0 20px 0 20px;
	width: 65%;
	background-color:#ffffff;
	margin-right: 0.6%;
}
#announcement .link {
	float: left;
	height: 30px;
	padding:2px;
	background-color: #ffffff;
}
#announcement .link a {
	float: left;
	width: 42px;
	height: 22px;
	overflow: hidden;
	display: block;
	line-height: 26px;
	text-indent: 8px;
	font-weight: bold;
	color: #f7f7f5;
	background-color: #bbbab0;
	border-bottom: 2px solid #8d8c80;
	border-radius:4px;
}
#announcement .link a:hover {
	text-decoration: none;
	border-top: 1px solid #bbbab0;
	border-bottom: 1px solid #bbbab0;
}
#announcement .navi {
	float: left;
	width: 10%;
	height: 28px;
	margin-left: 0.6%;
}
#announcement .navi .naviRight,
#announcement .navi .naviLeft {
	width: 25%;
	height: 28px;
	cursor:pointer;
}
#announcement .navi .naviRight{
	float:right;
    background: url("/louis/pc/img/common/arrow_right.png") no-repeat 50% 50%;
    background-size: 12px auto;
}
#announcement .navi .naviLeft {
	float:left;
    background: url("/louis/pc/img/common/arrow_left.png") no-repeat 50% 50%;
    background-size: 12px auto;
}
#announcement .navi .num {
	height: 28px;
	position: relative;
	text-align: center;
	margin: 0 auto;
	width: 50px;
	background-color:#ffffff;
}
#announcement .navi li a {
	display: block;
	overflow: hidden;
	text-indent: -9999px;
}
#announcement a {
	text-decoration: none;
}
#announcement a:hover {
	text-decoration: underline;
}

.releaseDay {
	   font-size:85%;
	   float:right;
	   color:#929292;
	   text-align:right;
	   margin: 0 auto;
}
.staffRoll  { font-size:85%; }


