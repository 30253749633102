@charset "UTF-8";

@import "../../../anne/scss/sp/atoms/colors";
@import "../../../anne/scss/sp/templates/layout/font-variable";

/* 配信予定リスト、検索リスト、チェックリストの見出し表示タブのスタイル調整 */
@import "show_type_list";
// 検索履歴
@import "./list/list_search_history";

/***************************************
*
*	1.
*
***************************************/

$key-color: #1d3850;

/* 1.tagtop  */
/* ------------------------------------------------------------------------------------ */

.contents-banner h2 img {
  width: 100%;
}

#pagetop-copy {
  background: #fff url(/louis/pc/img/common/line-symbol.png) repeat-x 0 top;
  margin: 12px 0 14px;
}

#pagetop-copy h1 {
  font-weight: bold;
  font-size: 161.6%;
  color: #2f2f2f;
  padding: 17px;
}

#pagetop-copy-inner {
  padding: 0 21px 21px;
  line-height: 1.5em;
}

.tagWrap {
  background: #fff;
}

.tagWrap ul {
  padding: 17px 7px 0 7px;
}

.tagWrap ul li {
  float: left;
  margin: 0 4px 20px 0;
}

.tagWrap ul li a {
  background: #f6f6f6;
  color: #1d4a6d;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 4px 10px;
  margin: 1px 0 0 3px;
  font-weight: bold;
  text-decoration: none;
}

.tagWrap ul li a:hover {
  opacity: 0.8;
}

.tl-uplink {
  text-align: right;
  margin: 0 0 4px 0;
  font-size: 93%;
}

.tl-uplink a {
  text-decoration: none;
}

.tl-uplink a:hover {
  text-decoration: underline;
}

/* 2. footer style */
/* ------------------------------------------------------------------------------------ */
/*#page-footer ul {
  float: left;
  margin: 0 21px 0 0;
}

#page-footer ul li,
#page-footer p {
  float: left;
  font-size: 93%;
}

#page-footer ul li a,
#page-footer p a {
  text-decoration: none;
}

#page-footer ul li a:hover,
#page-footer p a:hover {
  text-decoration: underline;
}
 */

/* 3. list style */
/* ------------------------------------------------------------------------------------ */
.list #aside {
  float: left;
  margin: 0 12px 0 0;
}

.search-sidebox {
  background: #fff;
  padding: 0 0 10px 0;
  margin: 0 0 7px 0;
}

#tl-aside {
  height: 32px;
  background: #fff;
  margin: 0 0 7px 0;
}

#search-ranking {
  margin: 0 0 14px 0;
}

#yaesu #tl-aside.state-yaesu {
  background: #5a1818;
  color: #fff;
}

#akihabara #tl-aside.state-yaesu {
  background: #ffa91c;
  color: #fff;
}

#shinjuku #tl-aside.state-yaesu {
  background: #0094d6;
  color: #fff;
}

#ikebukuro #tl-aside.state-yaesu {
  background: #ff8ada;
  color: #fff;
}

#newuser #tl-aside.state-yaesu {
  background: #7bc8ce;
  color: #fff;
}

#tl-aside h2 {
  padding: 9px 0 0 14px;
  font-weight: bold;
  font-size: 93%;
  float: left;
}

.search-sidebox h3 {
  border-bottom: 2px solid #cbced1;
  padding: 10px 0 8px 14px;
  font-weight: bold;
  font-size: 93%;
}

.search-sidebox ul {
  padding: 4px 4px 0 4px;
}

.search-sidebox ul li {
  margin: 0 0 1px 0;
  font-size: 93%;
}

.search-sidebox ul li a {
  text-decoration: none;
  display: block;
  padding: 5px 13px;
  position: relative;
}

.search-sidebox ul li a:hover {
  text-decoration: underline;
}

.search-sidebox ul li a.search-targetText {
  background: #bbbaad;
  color: #fff;
  font-weight: bold;
  border: 1px solid #bbbaad;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
}

.search-deleteBtn {
  position: absolute;
  top: 1px;
  right: 1px;
  display: block;
  background: url(/louis/pc/img/common/btn-search-delete.png) no-repeat 0 0;
  width: 23px;
  height: 23px;
  text-indent: -9999px;
}

.search-deleteBtn:hover {
  background-position: 0 -23px;
}

.list-banner p img {
  width: 100%;
}

.list-banner p {
  margin: 0 0 10px 0;
}

.btn-searchMore a {
  display: block;
  background-color: #a4a397;
  border-radius: 4px;
  box-shadow: 0 2px 0 #807e6a;
  color: #fff;
  font-size: 13px;
  margin: 5px auto 0;
  text-align: center;
  line-height: 26px;
  width: 207px;
  height: 26px;

  &:hover {
    box-shadow: 0 -2px 0 #807e6a;
    text-decoration: none;
    transform: translateY(2px);
  }
}

.search-allNum {
  color: #fff;
  font-weight: normal;
  font-size: 93%;
}

.top-sortWrap {
  background: #fff;
  height: 39px;
  overflow: hidden;
  margin: 0 0 7px 0;
  position: relative;
}

.sort-select {
  float: left;
  margin: 5px 0 0 5px;
}

.pager {
  position: absolute;
  top: 7px;
  left: 180px;
}

.pager.move {
  left: 27%
}

.pager ul {
  float: left;
}

.pager ul li {
  float: left;
  margin: 3px 7px 0;
}

.pager ul li a {
  text-decoration: none;
}

.pager ul li a:hover {
  text-decoration: underline;
}

.pager ul li a span.current {
  background: #bbbaad;
  border: 1px solid #bbbaad;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  padding: 2px 6px;
  color: #fff;
}

.pager-prev,
.pager-next {
  float: left;
}

.pager-prev {
  margin: 0 14px 0 0;
}

.pager-next {
  margin: 0 0 0 14px;
}

.pager-prev a {
  background: url(/louis/pc/img/list/btn-pager-prev.png) no-repeat 0 0;
  text-indent: -9999px;
  overflow: hidden;
  display: block;
  width: 23px;
  height: 23px;
}

.pager-prev a:hover {
  background-position: 0 -23px;
}

.pager-next a {
  background: url(/louis/pc/img/list/btn-pager-next.png) no-repeat 0 0;
  text-indent: -9999px;
  overflow: hidden;
  display: block;
  width: 23px;
  height: 23px;
}

.pager-next a:hover {
  background-position: 0 -23px;
}

.page ul {
  width: 300px;
}

.page ul li {
  float: left;
}

.top-sortRight {
  float: right;
}

.foot-pageNum {
  margin: 12px 14px 0 0;
  font-size: 93%;
}

.btn-series,
.btn-series-off {
  float: left;
  margin: 10px 10px 0 0;
}

.btn-series a,
.btn-series-off a {
  text-indent: -9999px;
  overflow: hidden;
  display: block;
}

.btn-series a {
  background: url(/louis/pc/img/list/btn-series.png) no-repeat 0 0;
  width: 91px;
  height: 19px;
}

.btn-series-off a {
  background: url(/louis/pc/img/list/btn-series-off.png) no-repeat 0 0;
  width: 61px;
  height: 19px;
}

.btn-series a:hover,
.btn-series a.current,
.btn-series-off a:hover,
.btn-series-off a.current {
  background-position: 0 -19px;
}

#sort-select {
  position: relative;
  margin: 10px 0 0 0;
}

#sortDiv {
  background: url(/louis/pc/img/list/sort-select.png) no-repeat 0 0;
  width: 138px;
  height: 20px;
  position: absolute;
  top: 6px;
  left: 6px;
  font-size: 12px;
  white-space: nowrap;
  overflow: hidden;
  -webkit-text-overflow: ellipsis;
  text-overflow: ellipsis;
  color: #2f2f2f;
  font-size: 85%;
  padding: 7px 0 0 10px;
}

select#sortDropdownBox, select#sortDropdownBox1, select#sortDropdownBox2, select#sortDropdownBox3 {
  position: absolute;
  zoom: 1.1;
  z-index: 2;
  opacity: 0;
  width: 130px;
}

select#sortDropdownBox option, select#sortDropdownBox1 option, select#sortDropdownBox2 option, select#sortDropdownBox3 option {
  color: #6e6e6e;
  font-size: 93%;
  padding: 5px 28px;
  border-bottom: 1px solid #f8f8f8;
}

/* 4. list detail style */
/* ------------------------------------------------------------------------------------ */
.list .row,
.ranking .row {
  margin: 0 0 7px 1px;
}

.detailbookItem {
  background: #fff;
  padding: 0 0 14px 0;
}

.detailbookItem.bought {
  background:#ccc;
}

.detailbookItem.subscription {
  background: #ccc;
}

.detailbookItem .bookSubscription {
  position: absolute;
  z-index: 100;
  color: #FFF;
  text-align: center;
  background-image: url(/anne/img/common/icon-sbsc.svg);
  // 左上に使うアイコンなので、とりあえず左上寄せに
  background-size: 48px 20px;
  width: 48px;
  height: 20px;
}

.detailLeftSec {
  width: 140px;
  float: left;
  position: relative;
  margin: 13px 14px 0 13px;
}

.detailbookImg a img {
  border: 1px solid #bababa;
  max-width: 200px;
}

.detailStateUl {
  margin: 4px 0 0 0;
}

.detailStateUl li {
  float: left;
  margin: 0 2px 1px 0;
}

.detailStateUl li.last {
  margin: 0 0 0 0;
}

.detailRightSec {
  float: right;
}

.detailRightSec h3, .detailRightSec h2 {
  margin: 14px 14px 6px 0;
}

.detailRightSec h3 a, .detailRightSec h2 a {
  text-decoration: none;
  font-size: 138.5%;
  font-weight: bold;
}

.detailRightSec h3 span.flagspan, .detailRightSec h2 span.flagspan, p.bi_checklist-content_title span.flagspan {
  padding: 0 3px 0 3px;
  vertical-align: middle;
}

.detailRightSec .series-grouped h3, .detailRightSec .series-grouped h2 {
  display: inline-block;
}

.detailRightSec .series-grouped .series-list-link a {
  color: #1d3850;
  font-weight: bold;
  text-decoration: none;
}

.writerDl {
  margin: 0 14px 0 2px;
}

.writerDl dt {
  font-size: 93%;
  color: #929292;
  float: left;
  margin: 0 8px 0 0;
  font-weight: bold;
}

.writerDl dd {
  float: left;
  margin: 0 15px 0 0;
}

.writerDl dd a {
  text-decoration: none;
  color: #929292;
}

.writerDl dd a:hover {
  text-decoration: underline;
}

.detailRightSec p {
  margin: 0 14px 0 0;
}

.detailRightSec p.detailPrice {
  margin: 13px 0 0 1px;
  color: #e40007;
  font-weight: bold;
  font-size: 123.1%;
}

.detailRightSec p.detailPrice .yen {
  font-size: 80%;
}

.detailRightSec p.detailPrice .tax {
  font-size: 80%;
}

.detailRightSec h4 {
  font-weight: bold;
  margin: 14px 14px 7px 1px;
  font-size: 108%;
  line-height: 1.4em;
}

.list-detail-txt {
  line-height: 1.4;
}

.text-more-btn {
  text-align: right;
  font-size: 93%;
}

.detail-bottom {
  margin: 2px 0 0 0;
}

.genleWrap {
  float: left;
  width: 45%;
}

.genleWrap dl dt {
  font-size: 93%;
  color: #929292;
  float: left;
  margin: 0 8px 4px 0;
  font-weight: bold;
}

.genleWrap dl dd {
  font-size: 93%;
  float: left;
  margin: 0 15px 0 0;
}

.genleWrap dl dd a {
  text-decoration: none;
  color: #929292;
}

.genleWrap dl dd a:hover {
  text-decoration: underline;
}

dl.genleDl,
dl.labelDl {
  margin: 0 0 0 0;
}

.detailCartWrap {
  float: right;
  margin: 22px 14px 0 0;
}

.detailCartWrap li {
  float: left;
}

.list-detail-precheck,
.list-detail-prechecked,
.list-detail-checked,
.list-detail-trial,
.list-detail-free,
.list-detail-check {
  margin: 0 5px 0 0;
}

.list-detail-check a,
.list-detail-checked span,
.list-detail-cart a,
.list-detail-point a,
.list-detail-gift a,
.list-detail-free a,
.list-detail-cart-in a,
.list-detail-precheck a,
.list-detail-prechecked span,
.list-detail-bought span,
.list-detail-trial a,
.list-detail-get span,
.list-detail-matometecart a {
  text-indent: -9999px;
  overflow: hidden;
  display: block;
  width: 79px;
  height: 33px;
}

.list-detail-check a {
  background: url(/louis/pc/img/list/btn-check-middle.png) no-repeat 0 0;
}

.list-detail-trial a {
  background: url(/louis/pc/img/list/btn-trial-middle-2.png) no-repeat 0 0;
}

.list-detail-cart a {
  background: url(/louis/pc/img/list/btn-cart-middle.png) no-repeat 0 0;
}

.list-detail-bought span {
  background: url(/louis/pc/img/list/btn-bought-middle.png) no-repeat 0 0;
}

.list-detail-get span {
  background: url(/louis/pc/img/list/btn-get-middle.png) no-repeat 0 0;
}

.list-detail-point a {
  background: url(/louis/pc/img/list/btn-point-middle.png) no-repeat 0 0;
}

.list-detail-gift a {
  background: url(/louis/pc/img/list/btn-gift-middle.png) no-repeat 0 0;
}

.list-detail-free a {
  background: url(/louis/pc/img/list/btn-free-middle.png) no-repeat 0 0;
}

.list-detail-cart-in a {
  background: url(/louis/pc/img/list/btn-cart-in-middle01.png) no-repeat 0 0;
}

.list-detail-precheck a {
  background: url(/louis/pc/img/list/btn-check-middle.png) no-repeat 0 0;
}

.list-detail-checked span {
  background: url(/louis/pc/img/list/btn-checked-middle.png) no-repeat 0 0;
}

.list-detail-prechecked span {
  background: url(/louis/pc/img/list/btn-checked-middle.png) no-repeat 0 0;
}

.list-detail-matometecart a {
  background: url(/louis/pc/img/list/btn-matometecart-middle.png) no-repeat 0 0;
}

.list-detail-check a:hover,
.list-detail-cart a:hover,
.list-detail-gift a:hover,
.list-detail-point a:hover,
.list-detail-free a:hover,
.list-detail-precheck a:hover,
.list-detail-trial a:hover,
.list-detail-matometecart a:hover {
  background-position: 0 -33px;
}

.list-detail-bought-2 {
  font-family: $base-font-family;
  width: 202px;
  height: 33px;
  overflow: hidden;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  border-bottom: 2px solid #4e4e4e;
  background: #e0e0e0;
  text-align: center;
  margin-bottom: 12px;
}

.list-detail-bought-2 {
  font-family: $base-font-family;
  width: 85px;
  height: 31px;
  overflow: hidden;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  border-bottom: 2px solid #4e4e4e;
  background: #e0e0e0;
  text-align: center;
  margin-bottom: 12px;
  position: relative;
}

.list-detail-bought-2:hover {
  border-top: 2px solid #4e4e4e;
  border-bottom: none;
}

.list-detail-bought-2 a {
  color: #333131;
  width: 100%;
  display: block;
  font-size: 77%;
  font-weight: bold;
  text-decoration: none;
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
}

.list-detail-bought-2 a .ico {
  display: inline-block;
  -moz-transform: scale(1.2) translateX(1px) translateY(1px);
  -ms-transform: scale(1.3) translateX(-1px) translateY(1px);
  -webkit-transform: scale(1.3) translateX(-1px) translateY(1px);
  transform: scale(1.1) translateX(-1px) translateY(1px);
}


.icon-series {
  padding: 0 0 0 7px;
}

.label-new-detail-list {
  position: absolute;
  top: -6px;
  background: url(/louis/pc/img/detail/icon-detail-new.png) no-repeat 0 0;
  width: 62px;
  height: 56px;
  text-indent: -9999px;
  overflow: hidden;
  z-index: 60;
}

.label-prebuy-detail-list {
  position: absolute;
  top: -6px;
  background: url(/louis/pc/img/detail/icon-detail-pre.png) no-repeat 0 0;
  width: 62px;
  height: 56px;
  text-indent: -9999px;
  overflow: hidden;
  z-index: 60;
}

/* 5. ranking style */
/* ------------------------------------------------------------------------------------ */
.ranking #aside {
  float: left;
  margin: 0 12px 0 0;
}

#ranking-search {
  height: 32px;
  margin: 0 0 7px 0;
  padding: 0 0 0 0;
}

#ranking-search ul {
  margin: 0 0 0 8px;
}

#ranking-search ul li {
  float: left;
  margin: 6px 0 0 15px;
}

#ranking-search ul li a {
  text-indent: -9999px;
  overflow: hidden;
  display: block;
  padding: 0;
  margin: 0;
  background-image: url(/louis/pc/img/ranking/nav-ranking-search.png);
  background-repeat: no-repeat;
  height: 14px;
}

#ranking-search ul li#rannking-nav01 a {
  width: 68px;
  background-position: 0 0;
}

#ranking-search ul li#rannking-nav02 a {
  width: 23px;
  background-position: -95px 0;
}

#ranking-search ul li#rannking-nav03 a {
  width: 35px;
  background-position: -152px 0;
}

#ranking-search ul li#rannking-nav01 a:hover,
#ranking-search ul li#rannking-nav01 a.active {
  background-position: 0 bottom;
}

#ranking-search ul li#rannking-nav02 a:hover,
#ranking-search ul li#rannking-nav02 a.active {
  background-position: -95px bottom;
}

#ranking-search ul li#rannking-nav03 a:hover,
#ranking-search ul li#rannking-nav03 a.active {
  background-position: -152px bottom;
}

.rankingLeftSec {
  width: 135px;
  float: left;
  position: relative;
  margin: 13px 14px 0 13px;
}

.rankingbookImg a img {
  border: 1px solid #bababa;
  max-width: 127px;
}

.rankingRightSec {
  float: left;
}

.rankingNum {
  font-weight: bold;
  margin: 14px 0 0 0;
  font-size: 108%;
}

.icon-ranking {
  margin: 0 0 0 6px;
}

.rankingRightSec h3 {
  margin: 12px 14px 17px 0;
}

.rankingRightSec h3 a {
  text-decoration: none;
  font-size: 138.5%;
  font-weight: bold;
}

.rankingPrice {
  margin: 13px 0 0 1px;
  color: #e40007;
  font-weight: bold;
  font-size: 123.1%;
}

.rankingPrice .yen {
  font-size: 75%;
}

.rankingPrice .tax {
  font-size: 75%;
}

.ranking-no {
  position: absolute;
  z-index: 11;
}

.img-book a span.ranking-no img {
  border: none;
}

.pager-ranking {
  position: absolute;
  top: 7px;
  left: 28%;
}

.pager-ranking.move {
  left: 27%
}

.pager-ranking ul {
  float: left;
}

.pager-ranking ul li {
  float: left;
  margin: 3px 10px 0;
}

.pager-ranking ul li a {
  text-decoration: none;
}

.pager-ranking ul li a:hover {
  text-decoration: underline;
}

.pager-ranking ul li a span.current {
  background: #bbbaad;
  border: 1px solid #bbbaad;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  padding: 2px 6px;
  color: #fff;
}

.ranking .bookWidget {
  margin: 0 0 0 0;
}

/* 6. new style */
/* ------------------------------------------------------------------------------------ */
.shelf-designUl,
.planUl {
  background: url(/louis/pc/img/common/line-left-search.png) no-repeat 5px 0;
  margin: 5px 0 0 0;
}

/* 7. plan style */
/* ------------------------------------------------------------------------------------ */
.preDate-plan01 {
  color: #e40007;
  margin: 20px 15px 0;
  font-size: 93%;
}

.preDate-plan02 {
  color: #e40007;
  margin: 2px 15px 0;
  font-size: 93%;
}

.precheck-comp-Sec {
  position: absolute;
  z-index: 200;
  top: 0;
}

.precheck-comp {
  background: #efefeb;
  border: 1px solid #efefeb;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  padding: 7px 10px;
  z-index: 200;
  -moz-box-shadow: 0px 0px 5px #999;
  -webkit-box-shadow: 0px 0px 5px #999;
  -ms-box-shadow: 0px 0px 5px #999;
}

.detail-bottom .precheck-comp-Sec {
  top: 165px;
  right: 10px;
}

.bookItem .precheck-comp-Sec {
  top: 150px;
}

.comp-triangle {
  height: 0px;
  width: 0px;
  border: 8px solid #efefeb;
  border-color: transparent;
  border-top-color: #efefeb;
  position: absolute;
  left: 40%;
}

.detail-bottom .comp-triangle {
  left: 90%;
}

.btn-delete {
  text-indent: -9999px;
  overflow: hidden;
  cursor: pointer;
  background: url(/louis/pc/img/common/btn-itemdelete.png) no-repeat 0 0;
  width: 23px;
  height: 23px;
  position: absolute;
  top: 2px;
  right: 2px;
}

.btn-delete:hover {
  background-position: 0 -23px;
}

.comp-text {
  font-size: 93%;
  color: #E12A2E;
}

.detailRightSec .precheck-comp p {
  margin: 0 2px 0 0;
}

.series-btn-wrap {
  margin: 0 0 7px 0;
}

.series-btn-wrap ul {
  float: right;
}

.series-btn-wrap ul li {
  float: right;
  display: inline;
  margin: 0 0 0 7px;
}

.series-btn-wrap ul li a,
.series-btn-wrap ul li span {
  text-indent: -9999px;
  overflow: hidden;
  display: block;
  width: 192px;
  height: 32px;
}

.series-detail-link.list-page {
  text-align: right;
}

.series-detail-link.list-page a {
  font-weight: bold;
  text-decoration: none;
}

.series-detail-link.list-page.list-page-bottom {
  margin-bottom: 7px;
}

.series-btn-wrap ul li.btn-series-reservation {
  a {
    text-indent: 0;
  }

  span {
    display: inline;
  }

  .ico {
    top: 1px;
    font-size: 1.5rem;
  }
}

li.btn-series-bought span {
  background: url(/louis/pc/img/detail/btn-series-bought.png) no-repeat 0 0;
}

li.btn-series-checked span {
  background: url(/louis/pc/img/detail/btn-series-checked.png) no-repeat 0 0;
}

li.btn-series-cart a {
  background: url(/louis/pc/img/detail/btn-series-cart.png) no-repeat 0 0;
}

li.btn-series-cart a:hover {
  background: url(/louis/pc/img/detail/btn-series-cart.png) no-repeat 0 -32px;
}

li.btn-series-check a {
  background: url(/louis/pc/img/detail/btn-series-check.png) no-repeat 0 0;
}

li.btn-series-check a:hover {
  background: url(/louis/pc/img/detail/btn-series-check.png) no-repeat 0 -32px;
}

li.btn-series-cart-in span {
  background: url(/louis/pc/img/detail/btn-series-cart-in.png) no-repeat 0 0;
}

li.btn-writers-cart a {
  background: url(/louis/pc/img/detail/btn-writers-cart.png) no-repeat 0 0;
}

li.btn-writers-cart a:hover {
  background: url(/louis/pc/img/detail/btn-writers-cart.png) no-repeat 0 -32px;
}

li.btn-writers-cart-in span {
  background: url(/louis/pc/img/detail/btn-writers-cart-in.png) no-repeat 0 0;
}


div.bookItemInner h3.img-book.rankingIcon span {
  display: absolute;
  left: 0;
  top: -9px;
}

#itemSec p.detailPrice.schedulePrice {
}

#itemSec div.detailbookItem p.preDate {
  margin: 7px 0 0;
  font-size: 110%;
  font-weight: bold;
}


.snsBtn {
  display: flex;
  li:not(:last-child) {
    margin-right: 5px;
  }
}


ul.directDl {
  text-align: right;
  float: right;
  margin: 13px 10px 0 0;
}

ul.directDl li {
  display: inline-block;
  text-align: left;
  margin: 6px 10px 0 0;
  font-weight: bold;
}

ul.directDl li a {
  display: block;
  width: 79px;
  height: 33px;
  overflow: hidden;
  /* text-indent: -9999px; */
  text-indent: -1100px;
  text-decoration: none;
}

ul.directDl li a.firstBook {
  background: url(/louis/pc/img/list/btn-look-vol1.png) no-repeat 0 0;
}

ul.directDl li a.last1Book {
  background: url(/louis/pc/img/list/btn-look-new-pablic1.png) no-repeat 0 0;
}

ul.directDl li a.last2Book {
  background: url(/louis/pc/img/list/btn-look-new-pablic2.png) no-repeat 0 0;
}

ul.directDl li a:hover {
  background-position: 0 -33px;
}


div.matometeFormCheckAndSubmit {
  font-size: 12px;
  text-align: left;
}

div.matometeFormCheckAndSubmit div {
  font-weight: bold;
  margin: 7px 0;
}

.no-close .ui-dialog-titlebar-close {
  display: none;
}

div.ui-widget-header {
  border: 1px solid #2f2f2f;
  background: #999;
  color: #ffffff;
  font-weight: bold;
}

div.ui-dialog .ui-dialog-content {
  padding: 5px;;
}

/* safesarch */
.safesearch-advise-wapper-0match {
  background-color: #eef0f2;
  padding: 1px 0;
  margin-bottom: 20px;
}

.safesearch-advise-wapper {
  background-color: #fff;
  padding: 1px;
  margin-bottom: 20px;
}

#safesearch-advise {
  margin: 10px;
  border: 2px solid #e40007;
  background-color: #fff;
  padding: 10px;
}

#safesearch-advise .safesearch-advise-htxt {
  font-weight: bold;
  color: #e40007;
  font-size: 14px;
  display: inline-block;
  margin-bottom: 5px;
  letter-spacing: 1px;
}

.safesearch-advise-wapper-0match #safesearch-advise .safesearch-advise-htxt {
  font-size: 16px;
}

#safesearch-advise .safesearch-advise-txtinner {
}

#safesearch-advise p a {
  text-indent: inherit;
  width: auto;
  height: auto;
  background: none;
  display: inline-block;
  margin: 15px 0 5px 0;
  font-weight: bold;
  font-size: 14px;
}

#safesearch-advise p a:hover {
  background: none;
}

.not-r18-advise {
  margin: 10px;
  border: 2px solid #e40007;
  background-color: #fff;
  padding: 10px;
}

.not-r18-advise-htxt {
  font-weight: bold;
  color: #e40007;
  margin-bottom: 5px;
  font-size: 14px;
  letter-spacing: 1px;
}

.not-r18-advise-txtinner {
  line-height: 140%;
}

.not-r18-advise p a {
  font-weight: bold;
  font-size: 15px;
  display: inline-block;
  margin: 10px 0 5px 0;
}


.r18-advise {
  margin: 10px;
  border: 2px solid #e40007;
  background-color: #fff;
  padding: 10px;
}

.r18-advise-main {
  border: 2px solid #e40007;
  text-align: center;
  padding: 20px;
  background-color: #fff;
}

.r18-advise-htxt {
  font-weight: bold;
  color: #e40007;
  margin-bottom: 5px;
  font-size: 14px;
  letter-spacing: 1px;
}

.safesearch-advise-wapper-0match .r18-advise-htxt {
  font-size: 16px;
}

.r18-advise-htxt img {
  vertical-align: middle;
  padding-right: 5px;
}

.r18-advise-txtinner {
  line-height: 140%;
}

.r18-advise p a.r18-allbtn {
  text-indent: -9999px;
  overflow: hidden;
  display: inline-block;
  width: 379px;
  height: 35px;
  margin: 5px;
  box-sizing: border-box;
}

.r18-advise p a {
  font-weight: bold;
  font-size: 15px;
  display: inline-block;
  margin: 10px 0 5px 0;
}

.r18-advise p a.r18-allbtn:hover,
.r18-advise p a.r18-allbtn:hover {
  background-position: 0 -35px;
}

// 検索結果からグローバルストアへのリンク
.global-store-advise {
  margin: 10px;
  border: 2px solid #{$key-color};
  background-color: #fff;
  padding: 10px;
}

.global-store-advise-htxt {
  font-weight: bold;
  color: #{$key-color};
  margin-bottom: 5px;
  font-size: 14px;
  letter-spacing: 1px;
}

.global-store-advise-txtinner {
  line-height: 140%;
}

#safesearch-advise-main {
  border: 2px solid #e40007;
  text-align: center;
  padding: 20px;
  background-color: #f9eaeb;
}

#safesearch-advise-main span {
  font-weight: bold;
  font-size: 16px;
  color: #e40007;
}

#safesearch-advise-main p {
  margin: 10px 0 4px 0;
  font-weight: bold;
}

#safesearch-advise p a {
  text-indent: inherit;
  width: auto;
  height: auto;
  background: none;
  display: inline-block;
  margin: 15px 0 5px 0;
  font-weight: bold;
  font-size: 14px;
}

#safesearch-advise p a:hover {
  background: none;
}

#safesearch-advise-main {
  border: 2px solid #e40007;
  text-align: center;
  padding: 20px;
  background-color: #f9eaeb;
}

#safesearch-advise-main span {
  font-weight: bold;
  font-size: 16px;
  color: #e40007;
}

#safesearch-advise-main p {
  margin: 10px 0 4px 0;
  font-weight: bold;
}

#safesearch-advise p a {
  text-indent: inherit;
  width: auto;
  height: auto;
  background: none;
  display: inline-block;
  margin: 15px 0 5px 0;
  font-weight: bold;
  font-size: 14px;
}

#safesearch-advise p a:hover {
  background: none;
}

#safesearch-advise-main p a {
  text-indent: -9999px;
  overflow: hidden;
  display: inline-block;
  width: 379px;
  height: 35px;
}

#safesearch-advise-main p a {
  background: url(/louis/pc/img/list/safe_search_button.png) no-repeat 0 0;
}

#safesearch-advise-main p a:hover {
  background-position: 0 -35px;
}


.relatedseries-advise-wapper {
  background-color: #fff;
  padding: 1px;
  margin-bottom: 20px;
}

.relatedseries-advise {
  margin: 10px;
  border: 2px solid #1d3850;
  background-color: #fff;
  padding: 10px;
  font-weight: 700;
  font-size: 14px;
  letter-spacing: 1px;
}

#paperbook-advise {
  margin: 0;
  border: 2px solid #0969a5;
  background-color: #e5ebf3;
  padding: 10px;
}

#paperbook-advise-main {
  margin: 10px 0 5px;
  border: 2px solid #0969a5;
  text-align: center;
  padding: 20px;
  background-color: #e5ebf3;
}

#paperbook-advise-main span {
  font-size: 16px;
  color: #0969a5;
}

#paperbook-advise-main p {
  margin: 10px 0 4px 0;
}

#paperbook-advise p a,
#paperbook-advise-main p a {
  text-indent: -9999px;
  overflow: hidden;
  display: inline-block;
  width: 257px;
  height: 35px;
}

#paperbook-advise p a,
#paperbook-advise-main p a {
  background: url(/louis/pc/img/list/books_search_button.png) no-repeat 0 0;
}

#paperbook-advise p a:hover,
#paperbook-advise-main p a:hover {
  background-position: 0 -35px;
}

#paperbook-advise-main p {
  color: #0969a5;
}

#paperbook-advise-main p.paperbook-advise-main-title {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 140%;
  font-weight: bold;
}

/* #4431 */
#staticSource {
  margin: -5px 0px 5px 2px;
}

#staticSource a {
  display: block;
  margin-bottom: 2px;
}

#staticSource img {
  width: auto;
  max-width: 100%;
}

/* #4716 */
.onerankcampaign_ttl {
  color: #fff;
  font-weight: bold;
  font-size: 88%;
  padding: 6px 5px 5px 5px;
  margin: 10px 0 0 -15px;
  text-align: center;
}

#yaesu .onerankcampaign_ttl {
  background-color: #5a1818;
}

#akihabara .onerankcampaign_ttl {
  background-color: #ffa91c;
}

#shinjuku .onerankcampaign_ttl {
  background-color: #0094d6;
}

#ikebukuro .onerankcampaign_ttl {
  background-color: #ff8ada;
}

#newuser .onerankcampaign_ttl {
  background-color: #7bc8ce;
}

.onerankcampaign_box {
  margin: 0 0 10px -15px;
  padding: 7px 10px 0 10px;
}

#yaesu .onerankcampaign_box {
  border: solid 2px #5a1818;
}

#akihabara .onerankcampaign_box {
  border: solid 2px #ffa91c;
}

#shinjuku .onerankcampaign_box {
  border: solid 2px #0094d6;
}

#ikebukuro .onerankcampaign_box {
  border: solid 2px #ff8ada;
}

#newuser .onerankcampaign_box {
  border: solid 2px #7bc8ce;
}

/* #5101 */
.serch-nomatch-inquiry {
  border: 2px solid #ccc;
  text-align: center;
  padding: 20px;
  margin-bottom: 20px;
}

.serch-nomatch-inquiry .a-basic-btn {
  margin-top: 15px;
}

/* #6699 追加部分ここから */
#search-price .serch_price_range {
  padding: 0 10px 0;
  margin-top: 10px;
}

#search-price .serch_price_range .input_text_block {
  display: inline;
  margin: 0 2px;
  padding: 0 2px;
}

#search-price .serch_price_range .input_text_block .text {
  border: 1px solid #ccc;
  box-shadow: none;
  height: 28px;
  width: 50px;
  padding: 0 2px;
}

#search-price .serch_price_range .input_area {
  float: left;
}

#search-price .serch_price_range .serch_price_range_btnbox,
#search-minus .serch_price_range_btnbox {
  position: relative;
  background-color: #a4a397;
  border-radius: 4px;
  box-shadow: 0 2px 0 #807e6a;
  height: 24px;
  width: 24px;
  margin-top: 2px;
  cursor: pointer;
  float: left;
}
#search-price .serch_price_range .serch_price_range_btnbox:hover,
#search-minus .serch_price_range_btnbox:hover {
  background-color: #a4a397;
  border-radius: 4px;
  box-shadow: 0 -2px 0 #807e6a;
  margin-top: 4px !important;
  margin-bottom: 0 !important;
}

#search-price .serch_price_range .serch_price_range_btnbox::before,
#search-minus .serch_price_range_btnbox::before{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #ffffff;
  pointer-events: none;
}

#search-price .serch_price_range .price_search {
  float: left;
  font-size: 13px;
  margin: 0 0 0 3px;
  width: 46px;
}

#search-price .serch_price_range .price_search a {
  border-radius: 3px;
  height: 14px;
  line-height: 16px;
  padding: 7px 10px;
  text-align: center;
}

#search-price .serch_price_range .price_search a.disabled {
  background-color: #fff;
  color: #b7b7b7;
}

#search-price .serch_price_range_btnbox input,
#search-minus .serch_price_range_btnbox input{
  width: 100%;
  height: 100%;
  border: none;
  appearance: none;
  padding: 0;
  outline: none;
  background: transparent;
  color: transparent;
  box-shadow: none;
  margin: auto;
}

#search-minus ul {
  margin-top: 15px;
}

#search-minus li {
  margin: 0 10px 13px 10px;
}

#search-minus li input[type=checkbox] {
  margin-right: 5px;
}

#search-minus li input[type=checkbox]:checked + span.serch_through {
  text-decoration: line-through;
}

#search-minus .categoryImputbox {
  padding: 0;
}

#search-minus .categoryImputbox .input_text_block {
  float: left;
  margin-right: 4px;
}

#search-minus .categoryImputbox .input_text_block .text {
  border: 1px solid #ccc;
  box-shadow: none;
  height: 28px;
  width: 160px;
  padding: 0 3px;
  // margin-right: 3px;
}

/* #6699 追加部分ここまで */

.search-bottom-coin-note {
  background-color: #fff;
  padding: 10px;
  margin-bottom: 7px;

  p {
    line-height: 1.5;
  }

  a {
    color: $base-link-color;
  }
}

h4.ns-item12 {
  font-weight: bold;
}

h4.ns-item12 img {
  display: inline-block;
  margin-bottom: 3px;
  margin-left: 5px;
}

#whole #container #bodySec #pageWrap #pageWrapInner #centerCol .bi_section .sequels-link {
  padding-left: 34px;
  font-weight: bold;
  margin-top: 5px;
  margin-bottom: 8px;
}

/* accordion menu */

#asideInner section.jscAccordionMenu {
  padding-bottom: 0;
}

#asideInner .accordion-contents {
  padding-bottom: 10px;
}

#asideInner h3.accordion-trigger {
  position: relative;
  cursor: pointer;
}

#asideInner h3.accordion-trigger:hover {
  opacity: .7;
}

#asideInner h3.accordion-trigger:after {
  content: '';
  background: url(/louis/pc/img/common/sprite-accordion.png) no-repeat;
  display: block;
}

#asideInner h3.accordion-trigger:after {
  position: absolute;
  top: 7px;
  right: 10px;
  width: 20px;
  height: 20px;
}

#asideInner h3.accordion-trigger:after {
  background-position: 0 -20px;
}

#asideInner h3.accordion-trigger.active:after {
  background-position: 0 0;
}

#akihabara #asideInner h3.accordion-trigger:after {
  background-color: #ffa91c;
}

#shinjuku #asideInner h3.accordion-trigger:after {
  background-color: #0094d6;
}

#yaesu #asideInner h3.accordion-trigger:after {
  background-color: #5a1818;
}

#ikebukuro #asideInner h3.accordion-trigger:after {
  background-color: #ff8ada;
}

#newuser #asideInner h3.accordion-trigger:after {
  background-color: #7bc8ce;
}

#otona #asideInner h3.accordion-trigger:after {
  background-color: #f36381;
}

#goods #asideInner h3.accordion-trigger:after {
  background-color: #013E8A;
}
