@import "../colors";
@import "../z-index";
@import "button-base-mixin";



.a-close-btn {
  @include button-base;
  width: 18px;
  height: 18px;
  border: 1px solid $strong-red;
  background: #fff;
  color: $strong-red;

  .ico {
    padding: 0;
    font-weight: 700;
    font-size: 16px;
    line-height: 16px;
  }
}