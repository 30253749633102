// PC総合TOP store.scssをコピーして作成。 
@import "page";
@import "mainbanner";
@import "newsCarousels";
@import "block-link-list";
@import "inline-css-1";
@import "store";

//キャンペーンバナー
.m-swiper {
  position: relative;
}


// まずはここから
.o-contents-section-beginner {
  background-color: white;
  margin: 10px 20px 30px;
  padding: 20px 20px;
  
  .o-contents-section-ttl {
    border-left: unset;
    text-align: center;
  }
  
  .o-contents-section-body {
    .top-beginnerWrap {
      margin: 10px;
      display: flex;
      
      .top-btn {
        position: relative;
        display: inline-block;
        width: calc(50% - 3px);
        height: 44px;
        line-height: 38px;
        border-radius: 4px;
        text-decoration: none;
        box-sizing: border-box;
        text-align: center;
        
        .ico-txt {
          font-size: 14px;
          font-weight: bold;
        }
        
        .ico {
          font-size: 16px;
          padding: 0 5px 0 0;
        }
        
        span {
          vertical-align: middle;
        }
        
        &:first-child {
          margin: 0 6px 0 0;
        }
        
        &:hover,&:focus {
          opacity: .7;
        }
      }
      
      .top-btn-member {
        color: #fff;
        background: #f99403;
        box-shadow: 0 -2px 0 0 rgba(0, 0, 0, .3) inset;
      }
      
      .top-btn-beginner {
        color: #3a8f11;
        background: #fff;
        border: 1px solid #3a8f11;
        box-shadow: 0 -2px 0 0 rgba(62, 123, 0, .7) inset;
        
      }
    }
  }
}
  
// 各棚のバナー
.shelfBannerArea {
  min-width: 960px;
  overflow: hidden;
  margin: 7px 0;
  
  p {
    display: inline;
  }
}
