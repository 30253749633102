@charset "UTF-8";

/***************************************
*
*	1.
*
***************************************/

/* 1.mainBook  */
/* ------------------------------------------------------------------------------------ */
#intro {
	min-width: 1080px;
	/*overflow: hidden;*/
	background: #fff;
	/*padding: 2px 0 0 0;*/
	position: relative;
}
#alphaBg {
	position: absolute;
	/*background: url(/louis/pc/img/common/bg-alpha.png) repeat 0 0;*/
	width: 100%;
	min-width: 960px;
	display: block;
	height: 552px;
	z-index: 50;
}
//#intro ul {
//	height: 184px;
//	overflow: hidden;
//}
#intro ul#mainBook2 {
	margin-left: -60px;
}
//#intro ul li {
//	float: left;
//}
.mainBnrInner {
	width: 970px;
	overflow: hidden;
	text-align: left;
	margin: 0 auto;
}
#mainBnr01 {
	min-width: 960px;
	overflow: hidden;
	margin: 0 0 2px 0;
}
#mainBnr01 p {
	display: inline;
	/*margin: 0 2px 0 0;*/
}
#mainBnr02 {
	min-width: 960px;
	overflow: hidden;
}
#mainBnr02 p {
	display: inline;
	/*margin: 0 2px 0 0;*/
}
.mainbnr-close {
/*
	position: absolute;
	cursor: pointer;
	background: url(/louis/pc/img/common/btn-itemdelete.png) no-repeat 0 0;
	width: 23px;
	height: 23px;
	top: 3px;
	text-indent: -9999px;
	overflow: hidden;
	z-index: 10;
*/
}
.mainbnr-close:hover {
	background-position: 0 -23px;
}
#mainLarge-l01 .mainbnr-close,
#mainLarge-l02 .mainbnr-close,
#mainLarge-l03 .mainbnr-close,
#mainSmall-l01 .mainbnr-close,
#mainSmall-l02 .mainbnr-close,
#mainSmall-l03 .mainbnr-close,
#mainSmall-l04 .mainbnr-close {
	right: 10px;
}
#mainLarge-s01 .mainbnr-close,
#mainLarge-s02 .mainbnr-close,
#mainLarge-s03 .mainbnr-close,
#mainSmall-s01 .mainbnr-close,
#mainSmall-s02 .mainbnr-close,
#mainSmall-s03 .mainbnr-close,
#mainSmall-s04 .mainbnr-close {
	left: 349px;
}
#mainLarge-largeWrap,
#mainSmall-largeWrap {
/*
	position: relative;
	text-align: center;
	margin: 0 auto;
	width: 1040px;
*/
}
#arrow-mainbnrWrap,
#mainLarge-smallWrap,
#mainSmall-smallWrap {
/*
	position: relative;
	text-align: center;
	margin: 0 auto;
	width: 1040px;
*/
}
#mainLarge-l01 .mainIncludeSec,
#mainLarge-l02 .mainIncludeSec,
#mainLarge-l03 .mainIncludeSec,
#mainSmall-l01 .mainIncludeSec,
#mainSmall-l02 .mainIncludeSec,
#mainSmall-l03 .mainIncludeSec,
#mainSmall-l04 .mainIncludeSec {
/*
	text-align: center;
	margin: 0 auto;
	display: block;
	width: 1030px;
	padding: 0 0 18px 0;
	background-image: url(/louis/pc/img/index/arrow-mainbnr.png);
	background-repeat: no-repeat;
*/
}
#mainLarge-l01 .mainIncludeSec,
#mainLarge-l02 .mainIncludeSec,
#mainLarge-l03 .mainIncludeSec,
#mainSmall-l01 .mainIncludeSec,
#mainSmall-l02 .mainIncludeSec,
#mainSmall-l03 .mainIncludeSec,
#mainSmall-l04 .mainIncludeSec {
	width: 1030px;
}
#mainLarge-s01 .mainIncludeSec,
#mainLarge-s02 .mainIncludeSec,
#mainLarge-s03 .mainIncludeSec,
#mainSmall-s01 .mainIncludeSec,
#mainSmall-s02 .mainIncludeSec,
#mainSmall-s03 .mainIncludeSec {
	width: 375px;
}
#mainLarge-l01 .mainIncludeSec {
	background-position: 160px bottom;
}
#mainLarge-l02 .mainIncludeSec {
	background-position: 500px bottom;
}
#mainLarge-l03 .mainIncludeSec {
	background-position: 850px bottom;
}
#mainSmall-l01 .mainIncludeSec {
	background-position: 110px bottom;
}
#mainSmall-l02 .mainIncludeSec {
	background-position: 370px bottom;
}
#mainSmall-l03 .mainIncludeSec {
	background-position: 630px bottom;
}
#mainSmall-l04 .mainIncludeSec {
	background-position: 890px bottom;
}
#mainLarge-l01 .mainIncludeSecInner,
#mainLarge-l02 .mainIncludeSecInner,
#mainLarge-l03 .mainIncludeSecInner,
#mainSmall-l01 .mainIncludeSecInner,
#mainSmall-l02 .mainIncludeSecInner,
#mainSmall-l03 .mainIncludeSecInner,
#mainSmall-l04 .mainIncludeSecInner {
	height: 357px;
	overflow: hidden;
	position: relative;
}
#mainLarge-s01,
#mainLarge-s02,
#mainLarge-s03,
#mainSmall-s01,
#mainSmall-s02,
#mainSmall-s03,
#mainSmall-s04 {
	text-align: left;
	position: relative;
}
#mainLarge-s01 .mainIncludeSec,
#mainLarge-s02 .mainIncludeSec,
#mainLarge-s03 .mainIncludeSec,
#mainSmall-s01 .mainIncludeSec,
#mainSmall-s02 .mainIncludeSec,
#mainSmall-s03 .mainIncludeSec,
#mainSmall-s04 .mainIncludeSec {
/*
	display: block;
	padding: 0 0 18px 0;
	background-image: url(/louis/pc/img/index/arrow-mainbnr.png);
	background-repeat: no-repeat;
*/
}
#mainLarge-s01 {
	top: 0;
	left: -15px;
}
#mainLarge-s02 {
	top: 0;
	left: 330px;
}
#mainLarge-s03 {
	top: 0;
	left: 680px;
}
#mainSmall-largeWrap {
	margin-top: 90px;
}
#mainSmall-s01 {
	top: 0;
	left: -50px;
	width: 375px;
}
#mainSmall-s02 {
	top: 0;
	left: 200px;
	width: 375px;
}
#mainSmall-s03 {
	top: 0;
	left: 460px;
	width: 375px;
}
#mainSmall-s04 {
	top: 0;
	left: 710px;
	width: 375px;
}
#mainLarge-s01 .mainIncludeSec,
#mainLarge-s02 .mainIncludeSec,
#mainLarge-s03 .mainIncludeSec,
#mainSmall-s01 .mainIncludeSec,
#mainSmall-s02 .mainIncludeSec,
#mainSmall-s03 .mainIncludeSec,
#mainSmall-s04 .mainIncludeSec {
	background-position: 170px bottom;
}
#scrollStopWrap {
	margin: 0 auto;
	position: relative;
	text-align: center;
	max-width: 1040px;
}
#btn-bookscroll-stop {
	position: absolute;
	top: 10px;
	right: 0;
	z-index: 200;
}
#btn-bookscroll-open {
	top: 10px;
	right: 10px;
	z-index: 200;
}
#btn-bookscroll-stop a,
#btn-bookscroll-open a {
	text-indent: -9999px;
	overflow: hidden;
	display: block;
	height: 23px;
}
#btn-bookscroll-stop a {
	background: url(/louis/pc/img/index/btn-bookscroll-close.png) no-repeat 0 0;
	width: 149px;
}
#btn-bookscroll-open a {
	background: url(/louis/pc/img/index/btn-bookscroll-open.png) no-repeat 0 0;
	width: 131px;
	text-indent: 100%;
	white-space: nowrap;
}
#btn-bookscroll-stop a:hover,
#btn-bookscroll-open a:hover  {
	background-position: 0 -23px;
}
