// commons.scssのmixin分離
@import "../colors";

// 40x40:sサイズ,44x44:base, 80x40:lサイズ
// 最小サイズ 40x40用
$small-icon-size: 40px;
// 横長サイズ 80x40用
$long-icon-size: 80px;

//ボタンベース（40x40)
@mixin button-base {
    display: block;
    box-sizing: border-box;
    width: $small-icon-size;
    height: $small-icon-size;
    overflow: hidden;
    white-space: nowrap;
    border-radius: 4px;
    color: #fff;
    text-align: center;
    background: #fff;
    .ico {
        padding: 2px;
        font-size: 20px;
        display: block;
    }
    .ico-txt,
    .button-text {
        font-size: 10px;
        font-weight: 700;
        line-height: 1.2;
        display: block;
    }

    &.disable {
        color: $disable-btn-text-color;
        background-color: $disable-btn-bg-color;
        box-shadow: none;
        cursor: default;

        span, a {
            color: $disable-btn-text-color;
        }
    }
}

// ワイドボタンベース（画面幅いっぱい）
@mixin wide-button-base {
    position: relative;
    display: block;
    border-radius: 4px;
    height: 40px;
    text-align: center;
    color: #fff;
    .ico {
        position: absolute;
        top: 50%;
        font-size: 24px;
        margin: 0 4px 0 0;
        transform: translateY(-50%);
    }
    .ico-txt,
    .button-text {
        display: inline-block;
        margin: 0 0 0 30px;
        font-size: 14px;
        font-weight: 700;
        line-height: 40px;
    }
}


// 標準ボタンベース（max320x40)
// アイコンテキスト横並び
@mixin basic-button-base {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100%;
    height: 40px;
    max-width: 320px;
    margin: auto;
    border:1px solid $border-color;
    border-radius: 4px;
    font-size: 14px;
    font-weight: bold;
    text-align: center;
    box-shadow: 0 -1px 0 0 rgba(0, 0, 0, 0.4) inset;
    overflow: hidden;

    .ico {
        padding-right: 8px;
        font-size: 16px;
        vertical-align: middle;

        & + .ico-txt, & + .button-text {
            vertical-align: middle;
            font-weight: bold;
        }

        &::before {
            display: block;
        }

        @include ico-adjust;
    }

    // 無効時
    &:disabled, &.disabled {
        color: #ccc;
        background: #eef0f2;
        border: 1px solid $border-color;
        pointer-events: none;
        box-shadow: none;
    }
}

// 色付き標準ボタンベース（max320x40)
// アイコンテキスト横並び
@mixin basic-color-button-base {
    @include basic-button-base;
    border: none;
    color: #ffffff;
}

// アイコン位置調整
@mixin ico-adjust {
    &.ico-cart::before {
        font-size: 1.2em;
        margin-top: -0.2em;
    }

    &.ico-gift::before {
        margin-top: -0.1em;
    }
}

// htmlの<button>タグのリセット
@mixin button-reset-css {
    background-color: transparent;
    border: none;
    cursor: pointer;
    outline: none;
    padding: 0;
    appearance: none;
}
