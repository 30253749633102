@import "../../../../anne/scss/sp/atoms/colors";
@import "../../../../anne/scss/sp/atoms/buttons/button-base-mixin";
@import "../../../../anne/scss/sp/atoms/buttons/close-btn";


.search-sidebox-notice {
  color: #999999;
  float: right;
  margin-right: 10px;
}

// 優先度獲得競争の結果こうなった
.search-history-list-el,
.search-sidebox ul li.search-history-list-el {
  display: flex;
  align-items: center;
  overflow: hidden;
  margin: 5px 0;
}

.search-history-manual-list-el,
.search-sidebox ul li.search-history-manual-list-el {
  display: flex;
  overflow: hidden;
  margin: 5px 0 0 13px;
}

.search-history-list-el-close-box {
  @include button-reset-css;
  display: block;
  margin: 5px 8px 5px 5px;
  width: 18px;
  height: 18px;
  flex-grow: 0;
  flex-shrink: 0;
}

// 優先度獲得競争の結果こうなった
.search-sidebox ul li a.search-history-list-el-link,
.search-sidebox ul li a.search-history-manual-list-el-link {
  display: inline-block;
  padding: 0;
}

.search-history-list-el-link-text,
.search-history-manual-list-el-link-text {
  color: $base-link-color;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

@mixin search-history-btn-mixin($color) {
  display: block;
  position: relative;
  width: 50px;
  height: 24px;
  border-radius: 16px;
  font-size: 11px;
  border: $color solid 1px;
  color: $color;
  flex-grow: 0;
  flex-shrink: 0;
  padding-left: 5px;
  margin: auto 0 auto auto;

  &:hover {
    opacity: .6;
  }
}

.search-history-list-el-save-btn {
  @include button-reset-css;
  @include search-history-btn-mixin($base-color);

  &:disabled {
    color: $disable-btn-text-color;
    border: $disable-btn-text-color solid 1px;
    cursor: initial;
  }
}

.search-history-list-el-save-btn-text {
  font-weight: bold;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.search-sidebox ul li a.search-history-manual-list-el-link-delete-btn {
  @include button-reset-css;
  @include search-history-btn-mixin($strong-red);
}

.search-history-manual-list-el-link-delete-btn-text {
  font-weight: bold;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}