@CHARSET "UTF-8";
/*
#buyHistory .sectionWrap {
    display: block;
    margin-left: 0;
    overflow: hidden;
    position: relative;
    top: 0;
    width: 100%;
    height: 150px;
    z-index: 9;
}
*/
.purchaseListOuter {
	left: 0;
	width: 100000px;
	position:relative;
}
.purchaseListInner {
	position: relative;
}
.purchaseListInner .itemWrap {
	display: block;
	float: left;
	margin: 0 9px 0 0;
	position: relative;
	width: 105px;
	height: 160px;
}
.purchaseListInner .itemWrap .item {
	display: block;
	margin: 0 auto;
	position: absolute;
	text-align: center;
	z-index: 10;
	background: #ffffff;
	width: 105px;
	height: 145px;
	right: -25px;
}
.itemWrap .item .image a img {
	height: 121px;
	border: 1px solid #b7b7b7;
	margin: 12px 0 0 0;
}

.purchaseListInner .itemWrap div.shadow {
	position:absolute;
	width: 94px;
	height: 3px;
	bottom:18px;
	text-align:center;
	margin: 0 auto;
	display: block;
	z-index:5;

	border-radius: 100%;
	box-shadow: 0 0 10px 10px rgba(0,0,0,.2), inset 0 0 0 200px rgba(0,0,0,.2);
}

.purchaseListInner .itemWrap span.image img {
	max-height:116px;
	max-width:86px;
}

.btn-bookItem-left-now {
	position: absolute;
	left: 0;
	width: 26px;
	z-index: 11;
}
.btn-bookItem-right-now {
	position: absolute;
	width: 26px;
	right: 0;
	z-index: 11;
}
.btn-bookItem-left-now a,
.btn-bookItem-right-now a {
	text-indent: -9999px;
	overflow: hidden;
	display: block;
	width: 25px;
	height: 145px;
	position: absolute;
	top: 40%;
	z-index: 11;
}
.btn-bookItem-left-now a {
	background: url(/louis/pc/img/common/btn-bookitem-left.png) no-repeat -1px 47%;
	border-left: 4px solid #d4d4d4;
}
.btn-bookItem-right-now a {
	background: url(/louis/pc/img/common/btn-bookitem-right.png) no-repeat 1px 47%;
	border-right: 4px solid #d4d4d4;
}
.btn-bookItem-left-now a:hover {
	background: url(/louis/pc/img/common/btn-bookitem-left.png) no-repeat -27px 47%;
	border-left: 4px solid #404040;
}
.btn-bookItem-right-now a:hover {
	background: url(/louis/pc/img/common/btn-bookitem-right.png) no-repeat -25px 47%;
	border-right: 4px solid #404040;
}

/* #purchaseBalloon ------------------------------------------------------*/
#purchaseBalloon {
	background: none repeat scroll 0 0 #efefeb;
	border: 1px solid #efefeb;
	display: none;
	padding: 10px;
	position: absolute;
	z-index: 10;
	top: -20px;
	border-radius: 3px;
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
	filter: alpha(opacity=80);
	-moz-opacity: 0.8;
	opacity: 0.8;
	pointer-events: none;
}
#purchaseBalloon .balloonDate {
	font-size: 85%;
	margin: 0 0 3px 0;
}
#purchaseBalloon .balloonTitle {
	font-weight: bold;
}
/* animation ------------------------------------------------------*/
.purchaseListInner div.shadow.animation {
	right: -22px;
	-webkit-animation: shadow 1.0s 1;
	-moz-animation: shadow 1.0s 1;
	-ms-animation: shadow 1.0s 1;
	-webkit-transform:translate3d(0,0,0);
}

@-webkit-keyframes shadow {
	0% {
		-webkit-transform: scale(0);
		opacity: 0;
		-webkit-animation-timing-function: ease-in;
	}
	50% {
		-webkit-transform: scale(1);
		opacity: 1;
		-webkit-animation-timing-function: ease-out;
	}
	80% {
		-webkit-transform: scale(0.8);
		opacity: 0.8;
		-webkit-animation-timing-function: ease-in;
	}
	100% {
		-webkit-transform: scale(1);
		opacity: 1;
		-webkit-animation-timing-function: ease-out;
	}
}

@-moz-keyframes shadow {
	0% {
		-moz-transform: scale(0);
		opacity: 0;
		-moz-animation-timing-function: ease-in;
	}
	50% {
		-moz-transform: scale(1);
		opacity: 1;
		-moz-animation-timing-function: ease-out;
	}
	80% {
		-moz-transform: scale(0.8);
		opacity: 0.8;
		-moz-animation-timing-function: ease-in;
	}
	100% {
		-moz-transform: scale(1);
		opacity: 1;
		-moz-animation-timing-function: ease-out;
	}
}

@-ms-keyframes shadow {
	0% {
		-ms-transform: scale(0);
		opacity: 0;
		-ms-animation-timing-function: ease-in;
	}
	50% {
		-ms-transform: scale(1);
		opacity: 1;
		-ms-animation-timing-function: ease-out;
	}
	80% {
		-ms-transform: scale(0.8);
		opacity: 0.8;
		-ms-animation-timing-function: ease-in;
	}
	100% {
		-ms-transform: scale(1);
		opacity: 1;
		-ms-animation-timing-function: ease-out;
	}
}


.purchaseListInner div.bounce  {
	-webkit-animation: bounce 1.0s 1;
	-moz-animation: bounce 1.0s 1;
	-ms-animation: bounce 1.0s 1;
	-webkit-transform:translate3d(0,0,0);
}

@-webkit-keyframes bounce {
	0% {
		-webkit-transform: translateY(-500px);
		-webkit-animation-timing-function: ease-in;
	}
	50% {
		-webkit-transform: translateY(0px);
		-webkit-animation-timing-function: ease-out;
	}
	80% {
		-webkit-transform: translateY(-30px);
		-webkit-animation-timing-function: ease-in;
	}
	100% {
		-webkit-transform: translateY(0px);
	}
}

@-moz-keyframes bounce {
	0% {
		-moz-transform: translateY(-500px);
		-moz-animation-timing-function: ease-in;
	}
	50% {
		-moz-transform: translateY(0px);
		-moz-animation-timing-function: ease-out;
	}
	80% {
		-moz-transform: translateY(-30px);
		-moz-animation-timing-function: ease-in;
	}
	100% {
		-moz-transform: translateY(0px);
	}
}

@-ms-keyframes bounce {
	0% {
		-ms-transform: translateY(-500px);
		-ms-animation-timing-function: ease-in;
	}
	50% {
		-ms-transform: translateY(0px);
		-ms-animation-timing-function: ease-out;
	}
	80% {
		-ms-transform: translateY(-30px);
		-ms-animation-timing-function: ease-in;
	}
	100% {
		-ms-transform: translateY(0px);
	}
}
