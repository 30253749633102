// Mixin

// 中央揃え
// x or y or xy
@mixin centering($mode, $rotate: 0, $position: absolute) {
    position: $position;

    @if $mode == x {
        left: 50%;
        -ms-transform: translateX(-50%) rotate($rotate + deg);
        -webkit-transform: translateX(-50%) rotate($rotate + deg);
        transform: translateX(-50%) rotate($rotate + deg);
    }
    @if $mode == y {
        top: 50%;
        -ms-transform: translateY(-50%) rotate($rotate + deg);
        -webkit-transform: translateY(-50%) rotate($rotate + deg);
        transform: translateY(-50%) rotate($rotate + deg);
    }
    @if $mode == xy {
        left: 50%;
        top: 50%;
        -ms-transform: translate(-50%, -50%) rotate($rotate + deg);
        -webkit-transform: translate(-50%, - 50%) rotate($rotate + deg);
        transform: translate(-50%, -50%) rotate($rotate + deg);
    }
}