@charset "utf-8";

/* 配信予定リスト、検索リスト、チェックリストの見出し表示タブのスタイル調整 */

.show-type {
  float: left;
  margin: 0 7px 0 0;
}

.show-type li {
  float: left;
  text-align: center;
  margin: 7px 0 0 0;
  font-size: 93%;
}

.show-type li.show-type01 {
  margin: 7px 1px 0 0;
}

.show-type li a {
  text-indent: 0px;
  position: relative;
  color: #fff;
  padding-top: 8px;
  overflow: visible;
  display: block;
  height: 18px;
  width: 73px;
  text-decoration: none;
  font-size: 1.2rem;
  font-weight: 600;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.show-type-list li a {
  background: #caddc8;
  box-shadow: none;
}

.show-type li.show-type01 a {
  background: #bbbab0;
}

.show-type li.show-type02 a {
  background: #bbbab0;
}

.show-type li.show-type01 a:hover,
.show-type li.show-type01 a.active {
  background: #1D374F;
}

.show-type li.show-type02 a:hover,
.show-type li.show-type02 a.active {
  background: #1D374F;
}

.show-type li.show-type01 a.active:before,
.show-type li.show-type01 a:hover:before,
.show-type li.show-type02 a.active:before,
.show-type li.show-type02 a:hover:before {
  content: "";
  position: absolute;
  top: 24px;
  left: 41px;
  margin-left: -11px;
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 9px 9px 9px;
  border: 6px solid transparent;
  border-top: 8px solid #1d374f;
  z-index: 0;
}